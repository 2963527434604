import { Button, Form, Input, Label, TextField } from '@troon/ui';
import { Title } from '@solidjs/meta';
import { useUser } from '../../providers/user';
import { gql, mutationAction, useMutation } from '../../graphql';

export default function Profile() {
	const user = useUser();
	const changeNameAction = useMutation(mutation);

	return (
		<div class="flex flex-col gap-8">
			<Title>Profile | My account | Troon</Title>
			<h1 class="text-3xl font-semibold">Profile</h1>
			<Form action={changeNameAction} class="flex flex-col gap-4">
				<TextField name="firstName">
					<Label>First name</Label>
					<Input value={user()!.me.firstName} />
				</TextField>

				<TextField name="lastName">
					<Label>Last name</Label>
					<Input value={user()!.me.lastName} />
				</TextField>

				<Button type="submit">Update name</Button>
			</Form>
		</div>
	);
}

const changeName = gql(`
mutation updateName($firstName: String!, $lastName: String!) {
	updateName(firstName: $firstName, lastName: $lastName) {
		firstName
		lastName
	}
}`);
const mutation = mutationAction(changeName, {
	revalidates: ['loggedInUser'],
});
